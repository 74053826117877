.app-bg{
    position: relative;
}
.app-bg img{
    width: 100%;
}
.app-bg-title{
    color: #fff; 
    width: 100%;
    position: absolute; 
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.app-bg-title>div:first-child{
    font-size: 2rem;
}
.app-bg-title>div:last-child{
    font-size: 2.5rem;
} 


.app-title-img{
    text-align: center;
    position: absolute;
    top: -1rem;
    left: 0;
    width: 100%;
    z-index: 1;
}
.app-title-title .app-title-img img{ 
    width: 15rem;
}
.app-list-title{
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    color: #2C3343;
    position: relative;
    margin-bottom: 3rem;
    
}
.app-list-title span{
     position: relative;
     font-weight: 600;
 }
.app-list-title span::before{
     content: "";
     position: absolute;
     bottom: -.2rem;
     left: 41%;
     width: 1.2rem;
     height: .2rem;
     background-image: linear-gradient(#25BDF9 0%, #1088F0 100%);
     border-radius: .1rem;
}

.app-about{
    margin-top: 8rem;
}
.app-about .app-list-title{
    margin-bottom: 1.5rem;
    color: #2C3343;
}
.app-about .app-list-title span::before{
    left: 40.5%;
}
.app-about-text{
    text-align: left;
    font-size: .8rem;
    line-height: 1.5rem;
    color: #2C3343;
    margin-bottom: 1.5rem;
}

.app-course{
    background: #0B121F;
    margin-top: 6rem;
    padding-top: 3.2rem;
}
.app-course .app-list-title{
    margin-bottom: 1.5rem;
    color: #fff;
}
.app-course .app-list-title span::before{
    left: 41.5%;
}
.app-course-desc{ 
    text-align: center;
    font-size: .8rem;
    color: #fff;
}
.app-course-out{
    position: relative;
}
.app-course-mask{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0B121F;
    z-index: 2;
    transition: height .8s;
}
.app-course-list{
    display: flex;
    margin-top: 3.5rem;
    margin-bottom: 5rem;
    position: relative;
    z-index: 1;
}
.app-course-list>div{
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
}
.app-course-list>div:first-child{
    z-index: 2; 
    align-items: flex-end;
    border-right: .05rem solid #314659;
}
.app-course-list>div:last-child{
    z-index: 1; 
    align-items: flex-start;
    height: 100%;
}

.app-course-btn{
    background: #1088F0;
    width: 5rem;
    height: 2rem;
    border-radius: .1rem;
    margin-right: -2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-bottom: 2.5rem;
    font-size: .8rem;
}
.app-course-btn-last{
    border: .05rem solid #314659; 
    background: #0B121F;
    color: #fff;
    font-size: .7rem;
    margin-bottom: 0rem;
}
.app-course-left{
    padding-right: 6.4rem;
    margin-bottom: 2.5rem;
    margin-right: 10rem;
    position: relative;
    opacity: 0;
    transition: margin-right .8s,opacity .6s;
}
.app-course-left-show{
    margin-right: 0rem !important;
    opacity: 1 !important;
}
.app-course-left>div:first-child{
    padding: .5rem;
    background: #EDEDED;
    box-shadow: 0 .25rem .5rem 0 rgba(0,0,0,0.30);
    border-radius: .2rem;
    font-size: .7rem;
    color: #2C3343;
    font-weight: 600; 
}
.app-course-line{
    position: absolute;
    top: 1rem;
    width: 6.4rem;
    right: 0;
    height: .05rem;
    background: #314659;
}
.app-course-dot{
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    background: #314659;
    position: absolute;
    top: .75rem;
    right: -.3rem;
}
.app-course-right{
    width: 6.4rem;
    margin-right: -16.4rem;
    position: relative;
    margin-bottom: 2.5rem;
    opacity: 0;
    transition: margin-right .8s,opacity .6s;
}
.app-course-right-show{
    margin-right: -6.4rem !important;
    opacity: 1 !important;
}
.app-course-right>div:first-child{
    padding: .5rem;
    margin-left: 6.4rem;
    width: max-content;
    background: #314659;
    box-shadow: 0 .25rem .5rem 0 rgba(0,0,0,0.15);
    border-radius: .2rem;
    font-size: .7rem; 
    color: #fff;
}
.app-course-right>div:first-child>div:first-child{ 
    margin-bottom: .5rem;
}
.app-course-right .app-course-line{
    left: 0;
    top: 1.8rem;
}
.app-course-right .app-course-dot{
    left: -.2rem;
    top: 1.55rem;
}


.app-group{
    margin-top: 8rem;
}
.app-group .app-list-title{
    margin-bottom: 1.5rem;
    color: #2C3343;
}
.app-group .app-list-title span::before{
    left: 40.5%;
}
.app-group-text{
    text-align: center;
    font-size: .8rem;
    line-height: 1.5rem;
    color: #2C3343;
    margin-bottom: 2.5rem;
}
.app-group-act{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.app-group-act>div{
    width: 32%;
    font-size: .9rem;
    color: #2C3343;
}
.app-group-act>div img{
    width: 100%;
}
.app-group-act>div>div{
    position: relative;
    padding-left: .75rem;
    margin-top: .7rem;
}
.app-group-act>div>div::before{
    position: absolute;
    content: '';
    width: .25rem;
    height: .75rem;
    background-image: linear-gradient(#25BDF9 0%, #1088F0 100%);
    top: .3rem;
    left: 0;
}
